import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

export default function ThirdPage() {

  function sendHireUs(event) {
    event.preventDefault()
    let name = document.getElementById("name").value
    let email = document.getElementById("email").value
    let tasktitle = document.getElementById("tasktitle").value
    let description = document.getElementById("description").value

    fetch("https://www.dataalp.com/hire", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, email: email, tasktitle: tasktitle, description: description }),
    }).then(res => {
      document.getElementById("hireAlp").reset();
      document.getElementById("messageSent").classList.add("d-block");
    })
  }


  return (
    <Layout>
      <Seo title="Hire Us" />
      <Navbar />

      <section id="hero" style={{paddingTop: '60px', minHeight: '38rem'}}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6  pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1 className="fs-2" style={{color: "#FFFFFF"}}>Hire Us</h1>
                <h2 className="fs-5" style={{ textAlign: "justify", lineHeight: "1.3", color: "#FFFFFF"}}>
                  An Alp is here to create your DataAlp report for big datasets
                  or to perform any sort of customized data analysis, from
                  preprocessing of your dataset to delivering a trained machine
                  learning model for predictions. Whatever the task be, Alp is
                  here to get in done for you
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img">
              <StaticImage
                src="../images/support.svg"
                alt=""
                placeholder="none"
                layout="fullWidth"
                data-aos="zoom-out"
                data-aos-delay="100"
                quality="100"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="contact">
        <div className="container">
          <div className="row justify-content-between">
            <div className="pt-4 pt-lg-0">
              <div data-aos="zoom-out text-center">
                <h3 className="text-center purple-heading">Get in correspondence with an Alp by filling the form below</h3>
                <h5 className="fs-5 text-center">and get closer to achieving more out of your dataset.</h5>
              </div>
            </div>
            <div className="row pt-5">
              <div
                className="col-lg-8 mx-auto mt-5 mt-lg-0"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <form
                  id="hireAlp"
                  onSubmit={event => sendHireUs(event)}
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      ></input>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      ></input>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="tasktitle"
                      id="tasktitle"
                      placeholder="Task"
                      required
                    ></input>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="description"
                      rows="5"
                      id="description"
                      placeholder="Task Description"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div id="messageSent" className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#a435f0", color: '#FFFFFF'}}
                      className="btn"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}
